import React, { useState, useEffect } from 'react';
import PopupForm from '../../components/PopupForm';
import SearchBar from '../../components/SearchBar';
import '../MySneakers/MySneakers.css'
import SearchBarByBrand from '../../components/SearchBarByBrand'
import SearchBarByOwner from '../../components/SearchBarByOwner';
import SearchBarByColorway from '../../components/SearchBarByColorway';

const MySneakers = () => {
  const [sneakers, setSneakers] = useState([]);
  const [averagePrice, setAveragePrice] = useState(null);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedSneaker, setSelectedSneaker] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [totalResalePrice, setTotalResalePrice] = useState(null);
  const [sortOption, setSortOption] = useState('');
  const [brandName, setBrandName] = useState('');
  const [brandStatistics, setBrandStatistics] = useState(null);



  //Function to get the list of sneakers
  useEffect(() => {
    const fetchSneakers = async () => {
      try {
        const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers');
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        setSneakers(data);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchSneakers();
  }, []);

  // Function to get the average price
  useEffect(() => {
    const fetchAveragePrice = async () => {
      try {
        const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/averagePrice');
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        setAveragePrice(data.averagePrice);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchAveragePrice();
  }, []);

  // New useEffect hook to fetch total resale price
  useEffect(() => {
    const fetchTotalResalePrice = async () => {
      try {
        const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/averageResalePrice');
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        setTotalResalePrice(data.totalResalePrice);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchTotalResalePrice();
  }, []);

  // This function is called when the "Update" button is clicked
  const handleUpdateClick = (sneaker) => {
    setSelectedSneaker(sneaker);
    setShowPopup(true);
  };

  // This function will be passed to PopupForm to update the sneaker list
  const updateSneakerList = (updatedSneaker) => {
    setSneakers((prevSneakers) =>
      prevSneakers.map((sneaker) =>
        sneaker._id === updatedSneaker._id ? updatedSneaker : sneaker
      )
    );
    setShowPopup(false);
  };

  // Function to handle the deletion of a sneaker
  const handleDeleteClick = async (sneakerId) => {
    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/${sneakerId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Update the state to filter out the deleted sneaker
        setSneakers(sneakers.filter(sneaker => sneaker._id !== sneakerId));
      } else {
        throw new Error('Failed to delete the sneaker');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Function to handle the search by model name
  const handleSearchByModelName = async (model) => {
    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/searchbymodel?model=${model}`, {
        method: 'GET'
      });
      if (response.ok) {
        console.log('search successfully');
        // Reset form or handle success
      } else {
        console.error('search Failed');
        // Handle error
      }
      console.log(`Making search request to: /api/sneakers/search`);

      const data = await response.json();
      if (response.ok) {
        setSneakers(data);
      } else {
        throw new Error('Search failed');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Function to handle the search by brand name
  const handleSearchByBrandName = async (brand) => {
    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/searchbybrand?model=${brand}`, {
        method: 'GET'
      });
      if (response.ok) {
        console.log('search successfully');
        // Reset form or handle success
      } else {
        console.error('search Failed');
        // Handle error
      }
      console.log(`Making search request to: /api/sneakers/search`);

      const data = await response.json();
      if (response.ok) {
        setSneakers(data);
      } else {
        throw new Error('Search failed');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Function to handle the search by owner name
  const handleSearchByOwnerName = async (owner) => {
    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/searchbyowner?owner=${owner}`, {
        method: 'GET'
      });
      if (response.ok) {
        console.log('search successfully');
        // Reset form or handle success
      } else {
        console.error('search Failed');
        // Handle error
      }
      console.log(`Making search request to: /api/sneakers/search`);

      const data = await response.json();
      if (response.ok) {
        setSneakers(data);
      } else {
        throw new Error('Search failed');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Function to handle the search by colorway
  const handleSearchByColorway = async (colorway) => {
    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/searchbycolorway?colorway=${colorway}`, {
        method: 'GET'
      });
      if (response.ok) {
        console.log('search successfully');
        // Reset form or handle success
      } else {
        console.error('search Failed');
        // Handle error
      }
      console.log(`Making search request to: /api/sneakers/search`);

      const data = await response.json();
      if (response.ok) {
        setSneakers(data);
      } else {
        throw new Error('Search failed');
      }
    } catch (error) {
      setError(error.message);
    }
  }

  // Function to handle search by brand for brand statistics
  const handleBrandStatistics = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/brandStatistics?brand=${brandName}`);
      const data = await response.json();
      if (response.ok) {
        setBrandStatistics(data);
      } else {
        throw new Error('Failed to fetch brand statistics');
      }
    } catch (error) {
      console.error('Error fetching brand statistics:', error);
      setError(error.message);
    }
  };

  // Function to sort sneakers
  const sortSneakers = (option, sneakersList) => {
    switch (option) {
      case 'retailPriceLowHigh':
        return [...sneakersList].sort((a, b) => a.retailPrice - b.retailPrice);
      case 'retailPriceHighLow':
        return [...sneakersList].sort((a, b) => b.retailPrice - a.retailPrice);
      case 'resalePriceLowHigh':
        return [...sneakersList].sort((a, b) => a.resalePrice - b.resalePrice);
      case 'resalePriceHighLow':
        return [...sneakersList].sort((a, b) => b.resalePrice - a.resalePrice);
      default:
        return sneakersList; // Default case to handle no sorting
    }
  };

  // Effect hook to sort sneakers whenever sortOption or sneakers array changes
  useEffect(() => {
    const sortedSneakers = sortSneakers(sortOption, sneakers);
    setSneakers(sortedSneakers);
  }, [sortOption, sneakers]);


  return (
    <div className="container">
      <div className="sneaker-collection">
        <h1>My Sneaker Collection</h1>
        {/* Add dropdown for sorting */}
        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="">Sort by</option>
          <option value="retailPriceLowHigh">Retail Price (Low to High)</option>
          <option value="retailPriceHighLow">Retail Price (High to Low)</option>
          <option value="resalePriceLowHigh">Resale Price (Low to High)</option>
          <option value="resalePriceHighLow">Resale Price (High to Low)</option>
        </select>
        {/*{error && <p>{error}</p>}*/}
        {error && <p>{"You have no sneakers in your collection"}</p>}
        {sneakers.map(sneaker => (
          <div key={sneaker._id}>
            <h2>{sneaker.brand} - {sneaker.model}</h2>
            <p>Owned by: {sneaker.owned_by}</p>
            <p>Colorway: {sneaker.colorway}</p>
            <p>Size: {sneaker.size}</p>
            <p>Purchase Date: {new Date(sneaker.purchaseDate).toLocaleDateString()}</p>
            <p>Retail Price: ${sneaker.retailPrice}</p>
            <p>Resale Price: ${sneaker.resalePrice}</p>
            <button onClick={() => handleUpdateClick(sneaker)}>Update</button>
            <button onClick={() => handleDeleteClick(sneaker._id)}>Delete</button>
          </div>
        ))}


        <></>
      </div>
      <div className="search-section">
        <SearchBar onSearch={handleSearchByModelName} searchResults={searchResults} />
        <SearchBarByBrand onSearch={handleSearchByBrandName} searchResults={searchResults} ></SearchBarByBrand>
        <SearchBarByOwner onSearch={handleSearchByOwnerName} searchResults={searchResults} ></SearchBarByOwner>
        <SearchBarByColorway onSearch={handleSearchByColorway} searchResults={searchResults}></SearchBarByColorway>
        <h2>My Sneaker Collection Statistics</h2>
        <div>Average Price By Sneaker: {averagePrice ? `$${averagePrice.toFixed(2)}` : 'Calculating...'}</div>
        <div>Total Resale Price: {totalResalePrice ? `$${totalResalePrice.toFixed(2)}` : 'Calculating...'}</div>
        <form onSubmit={handleBrandStatistics}>
          <input
            type="text"
            placeholder="Brand Statistics"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
        <div>
          {brandStatistics && (
            <div>
              <h2>Statistics for {brandName}</h2>
              {/* Replace `averagePrice` and `totalResalePrice` with the actual properties returned by your backend */}
              <p>Average Retail Price: ${brandStatistics.averageRetailPrice?.toFixed(2)}</p>
              <p>Average Resale Price: ${brandStatistics.averageResalePrice?.toFixed(2)}</p>
              <p>Total Retail Value: ${brandStatistics.totalRetailPrice?.toFixed(2)}</p>
              <p>Total Resale Value: ${brandStatistics.totalResalePrice?.toFixed(2)}</p>
            </div>
          )}
        </div>
      </div>
      {showPopup && selectedSneaker && (
        <PopupForm
          sneaker={selectedSneaker}
          closePopup={() => setShowPopup(false)}
          updateSneakerList={updateSneakerList}
        />
      )}
    </div>
  );
};

export default MySneakers;

import React, { useState } from 'react';
import './Signup.css'; // Make sure the CSS file is in the same directory

const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        name: 'anonymous', // Default value as per the model
        age: '',
        email: '',
        year: '',
        major: 'unknown' // Default value as per the model
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Post the login data to your backend
        try {
            const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/users', { // Make sure this endpoint is your login endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                console.log('Login successful');
                // Handle successful login, like redirecting to another page or storing the JWT token
            } else {
                console.error('Failed to login');
                // Handle errors, like showing a message to the user
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle network errors
        }
    };


    return (
        <div className="signup-container">
            <form onSubmit={handleSubmit}>
                <h2>Sign Up</h2>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Name (Optional)</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                {/*
                <div className="form-group">
                    <label htmlFor="age">Age</label>
                    <input
                        type="number"
                        id="age"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        required
                    />

                </div>
                <div className="form-group">
                    <label htmlFor="email">Email (Purdue Email)</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="year">Year</label>
                    <select name="year" id="year" value={formData.year} onChange={handleChange} required>
                        <option value="">Select Academic Year</option>
                        <option value="1">1 - Freshman</option>
                        <option value="2">2 - Sophomore</option>
                        <option value="3">3 - Junior</option>
                        <option value="4">4 - Senior</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="major">Major (Optional)</label>
                    <input
                        type="text"
                        id="major"
                        name="major"
                        value={formData.major}
                        onChange={handleChange}
                    />
                </div>
                */}
                <div className="form-group">
                    <button type="submit">Sign Up</button>
                </div>
            </form>
        </div>
    );
};

export default Signup;

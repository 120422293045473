import React, { useState } from 'react';

const AddSneakerForm = () => {
  const [formData, setFormData] = useState({
    owned_by: '',
    brand: '',
    model: '',
    colorway: '',
    purchaseDate: '',
    retailPrice: '',
    resalePrice: '',
    size: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Post the form data to your backend
    try {
      const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Sneaker added successfully');
        // Reset form or handle success
      } else {
        console.error('Failed to add sneaker');
        // Handle error
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="owned_by" placeholder="Owned by" value={formData.owned_by} onChange={handleChange} />
      <input type="text" name="brand" placeholder="Brand" value={formData.brand} onChange={handleChange} />
      <input type="text" name="model" placeholder="Model" value={formData.model} onChange={handleChange} />
      <input type="text" name="colorway" placeholder="Colorway" value={formData.colorway} onChange={handleChange} />
      <input type="date" name="purchaseDate" value={formData.purchaseDate} onChange={handleChange} />
      <input type="number" name="retailPrice" placeholder="Retail Price" value={formData.retailPrice} onChange={handleChange} />
      <input type='number' name="resalePrice" placeholder='Resale Price' value={formData.resalePrice} onChange={handleChange}/>
      <input type="number" name="size" placeholder="Size" value={formData.size} onChange={handleChange} />
      <button type="submit">Add Sneaker</button>
    </form>
  );
};

export default AddSneakerForm;

import React, { useState, useEffect } from 'react';
import styles from '../Users/Users.css'
import UpdateUserForm from '../../components/UpdateUserForm/UpdateUserForm';


const UsersWithSneakerCount = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [usersWithCount, setUsersWithCount] = useState([]);
    const [sortDirection, setSortDirection] = useState('asc');
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [currentUserToUpdate, setCurrentUserToUpdate] = useState(null);

    // Function to handle user deletion
    const handleDelete = async (userId) => {
        try {
            const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/users/${userId}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Could not delete user');
            }
            // Refresh the users list or handle the deleted user as needed
            setAllUsers(allUsers.filter(user => user._id !== userId));
        } catch (error) {
            console.error('Delete error:', error);
        }
    };

    // Function to handle user updates
    const handleUpdate = async (userId, updateData) => {
        try {
            const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/users/${userId}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updateData)
            });
            if (!response.ok) {
                throw new Error('Could not update user');
            }
            // Refresh the users list or handle the updated user as needed
        } catch (error) {
            console.error('Update error:', error);
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Make sure this endpoint matches your Express route
                const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/users/getwithsneakercount');
                if (!response.ok) {
                    console.log(response)
                    throw new Error('Could not fetch users and their sneaker counts');
                }
                const data = await response.json();
                setUsersWithCount(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await fetch('https://api.348snkrs.domains.muffinmanhosting.com/api/users/getusers');
                if (!response.ok) {
                    throw new Error('Could not fetch users');
                }
                const allUsers = await response.json();
                setAllUsers(allUsers);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchAllUsers();
    }, []);

    // New function to sort users based on sneaker count
    const sortUsers = (direction) => {
        setSortDirection(direction);
        setUsersWithCount(prevUsers => {
            return [...prevUsers].sort((a, b) => {
                return direction === 'asc' ? a.sneakerCount - b.sneakerCount : b.sneakerCount - a.sneakerCount;
            });
        });
    };


    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <div className={styles.sortContainer}>
                <label htmlFor="sortDropdown">Sort by Sneaker Count:</label>
                <select
                    id="sortDropdown"
                    onChange={(e) => sortUsers(e.target.value)}
                    value={sortDirection}
                >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </select>
            </div>
            <h1>Users List</h1>
            {allUsers.length > 0 ? (
                <ul>
                    {allUsers.map((user) => (
                        <li key={user._id} className={styles.userItem}>
                            <button onClick={() => {
                                setShowUpdateForm(true);
                                setCurrentUserToUpdate(user);
                            }}>
                                Update
                            </button>
                            <button onClick={() => handleDelete(user._id)}>
                                Delete
                            </button>
                            <div className={styles.userName}><h3>User: {user.name}</h3></div>
                            <p>Age: {user.age || 'Not provided'}</p>
                            <p>Email: {user.email || 'Not provided'}</p>
                            <p>Username: {user.username || 'Not provided'}</p>
                        </li>
                    ))}
                    {showUpdateForm && currentUserToUpdate && (
                        <UpdateUserForm
                            user={currentUserToUpdate}
                            onUpdate={handleUpdate}
                        />
                    )}
                </ul>
            ) : (
                <p>No users found.</p>
            )}
            <h1>Users and The Number of Sneakers They Own</h1>
            {usersWithCount.length > 0 ? (
                <ul>
                    {usersWithCount.map((user) => (
                        <li key={user._id} className={styles.userItem}>
                            <div className={styles.userName} >
                                <h3>User: {user.name}</h3>


                            </div>
                            <div className={styles.sneakerCount}>
                                Number of sneakers owned: {user.sneakerCount}
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No users found.</p>
            )}
        </div>
    );
};

export default UsersWithSneakerCount;

import { BrowserRouter, Routes, Route } from 'react-router-dom'

// pages & components
import Home from './pages/Home'
import Navbar from './components/Navbar/navbar';
import SneakerForm from './pages/SneakerForm'
import MySneakers from './pages/MySneakers/MySneakers';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import Users from './pages/Users/Users'
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route 
              path="/" 
              element={<Home />} 
            />
            <Route
              path="/sneaker-form"
              element={<SneakerForm/>}
            />
            <Route
              path="/my-sneakers"
              element={<MySneakers/>}
            />
            <Route
              path="/signup"
              element={<Signup/>}
            />
            <Route
              path="/login"
              element={<Login/>}
            />
            <Route
              path="/users"
              element={<Users/>}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;


import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import './navbar.css';  // Component level CSS

const NavigationBar = () => {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container fluid>  
        <LinkContainer to="/">
        <Navbar.Brand className="navbar-brand-custom">My Sneaker Collection!!</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* Space for additional nav links */}
            <LinkContainer to="/sneaker-form">
              <Navbar.Brand className="navbar-brand-custom">Sneaker Form</Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/my-sneakers">
              <Navbar.Brand className="navbar-brand-custom">My Sneakers</Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/users">
              <Navbar.Brand className="navbar-brand-custom">Users</Navbar.Brand>
            </LinkContainer>
            
          </Nav>
          <Nav>
            <LinkContainer to="/login">
              <Button variant="outline-light" className="me-2">Login</Button>
            </LinkContainer>
            <LinkContainer to="/signup">
              <Button variant="outline-light">Signup</Button>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;

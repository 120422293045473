import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {

  return (
    <div className="home">
      <h2>Home Page</h2>
    </div>
  )
}

export default Home
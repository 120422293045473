import React, { useState } from 'react';

const UpdateUserForm = ({ user, onUpdate, onCancel }) => {
  const [formData, setFormData] = useState({
    username: user.username,
    password: user.password, // Assuming you want to allow password updates
    name: user.name,
    age: user.age || '', // If age is null or undefined, default to an empty string
    email: user.email,
    year: user.year || '', // If year is null or undefined, default to an empty string
    major: user.major || '' // If major is null or undefined, default to an empty string
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onUpdate(user._id, formData);
  };

  return (
    <div className="update-form">
      <h3>Update User</h3>
      <form onSubmit={handleSubmit}>
        <label>Username:</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />

        <label>Password (leave blank to keep unchanged):</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />

        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />

        <label>Age:</label>
        <input
          type="number"
          name="age"
          value={formData.age}
          onChange={handleChange}
        />

        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />

        <label>Year:</label>
        <input
          type="text"
          name="year"
          value={formData.year}
          onChange={handleChange}
        />

        <label>Major:</label>
        <input
          type="text"
          name="major"
          value={formData.major}
          onChange={handleChange}
        />

        <div className="form-actions">
          <button type="submit">Save Changes</button>
          <button type="button" onClick={onCancel}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default UpdateUserForm;

import React, { useState, useEffect } from 'react';

const PopupForm = ({ sneaker, closePopup, updateSneakerList }) => {
  const [formData, setFormData] = useState({ ...sneaker });

  useEffect(() => {
    // Pre-fill the form when the selected sneaker is changed
    setFormData({ ...sneaker });
  }, [sneaker]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send the PATCH request to the backend to update the sneaker
    try {
      const response = await fetch(`https://api.348snkrs.domains.muffinmanhosting.com/api/sneakers/${sneaker._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const updatedSneaker = await response.json();
      if (response.ok) {
        updateSneakerList(updatedSneaker); // Invoke the function passed as a prop to update the sneaker list
        closePopup(); // Close the popup
      } else {
        throw new Error('Failed to update sneaker', updatedSneaker.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Edit Sneaker Info</h2>
        <form onSubmit={handleSubmit}>
          <label>Owned by:</label>
          <input
            type="text"
            name="owned_by"
            value={formData.owned_by}
            onChange={handleChange}
          />

          <label>Brand:</label>
          <input
            type="text"
            name="brand"
            value={formData.brand}
            onChange={handleChange}
          />

          <label>Model:</label>
          <input
            type="text"
            name="model"
            value={formData.model}
            onChange={handleChange}
          />

          <label>Colorway:</label>
          <input
            type="text"
            name="colorway"
            value={formData.colorway}
            onChange={handleChange}
          />

          <label>Purchase Date:</label>
          <input
            type="date"
            name="purchaseDate"
            value={formData.purchaseDate ? formData.purchaseDate.split('T')[0] : ''} 
            onChange={handleChange}
          />

          <label>Retail Price:</label>
          <input
            type="number"
            name="retailPrice"
            value={formData.retailPrice}
            onChange={handleChange}
          />

          <label>Resale Price:</label>
          <input
            type="number"
            name="resalePrice"
            value={formData.resalePrice}
            onChange={handleChange}
          />      

          <label>Size:</label>
          <input
            type="number"
            name="size"
            value={formData.size}
            onChange={handleChange}
          />

          <button type="submit">Submit Changes</button>
          <button type="button" onClick={closePopup}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
